// @flow
import type {$AxiosXHR, Axios} from "axios";
import qs from "query-string";
import {axiosProductInstance} from "../api";
import I18n from "../i18n/i18n";
import i18n from "i18next";
import type {ProductDetail} from "../service/productService";

export type PartCodeModel = {
	partCode: string,
	partCodeSymbol: string,
	fullPartCode: string,
	includesOptionalPart:boolean
}

export type ProductModel = {
	defaultWorkUnits: number,
	description: string,
	fitmentTypes: string[],
	imageUris: string[],
	name: string,
	partNo: string,
	partCode: string,
	partCodeSymbol: string,
	partNumberHistory: string[],
	productFitments: Array<{
		fitments: Array<{
			configuration: string,
			engine: string,
			gear: string,
			modelyear: string,
			subtype: string,
			type: string,
			variant: string
		}>,
		title: string
	}>,
	tags: string[],
	workUnits: Array<{
		value: number,
		vkCode: string
	}>
};
export type FacetEntry = {
	count: number,
	value: string,
	label: string
}
export type Facet = {
	entries: FacetEntry[],
	fieldName: string,
	label: string
}
export type ProductResponse = {
	content: ProductModel[],
	total: number,
	facets: Facet[],
	number: number,
	size: number,
	totalPages: number
};

export type PartSearchRequest = {
	partNumber: string,
	factoryCode: string,
	destinationCode: string,
	modelYearCode: string,
	optionCodes: string[],
	colorCode: string,
	interiorColorCode: string,
	vehicleBuildDate: Date,
	vehicleDecommissionDate: Date,
	vkConfiguration: string,
	vkModelYear: string,
	vkType: string,
	vkSubtype: string,
	vkVariant: string,
	vkEngine: string,
	vkGear: string,
	page: number,
	pageSize: number
}

class ProductApi {
	productClient: Axios;

	i18n: I18n;

	constructor() {
		this.productClient = axiosProductInstance;
		i18n.on("onInitialized", language => {
			this.setLanguage(language);
		});
		i18n.on("languageChanged", language => {
			this.setLanguage(language);
		});
	}

	async getByPartCodeAndMostRelevantPrecisionDetails(
		partCodes: PartCodeModel[],
		factoryCode: string,
		destinationCode: string,
		modelYearCode: string,
		optionCodes: string[],
		colorCode: string,
		interiorColorCode: string,
		figureNumber: string,
		vehicleBuildDate: Date,
		vehicleDecommissionDate: Date
	): Promise<ProductResponse> {
		const response: $AxiosXHR<ProductModel[]> = await this.productClient.post(
			"/v1/product/byPartCodeAndMostRelevantPrecisionDetails",
			{
				factoryCode: factoryCode,
				destinationCode: destinationCode,
				modelYearCode: modelYearCode,
				partCode: partCodes,
				optionCodes: optionCodes,
				colorCode: colorCode,
				interiorColorCode: interiorColorCode,
				figureNumber: figureNumber,
				vehicleBuildDate: vehicleBuildDate,
				vehicleDecommissionDate: vehicleDecommissionDate
			});
		return response.data;
	}

	async getByPartNumberAndVehicle(request: PartSearchRequest): Promise<ProductResponse> {
		const response: $AxiosXHR<ProductModel[]> = await this.productClient.post(
			"/v1/product/byPartNumberAndVehicle",
			{
				...request
			});
		return response.data;
	}

	async getByFilterParameters(productFilter, vehicleFilter, page, pageSize): Promise<ProductResponse> {
		const response: $AxiosXHR<ProductDetail[]> = await this.productClient.get(
			"/v1/product/search",
			{
				params: {
					...productFilter,
					...vehicleFilter,
					page,
					size: pageSize
				},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		);
		return response.data;
	}

	async getProductDetails(withExternalStocks: boolean, partNumbers: string[]): Promise<ProductDetail[]> {
		const response: $AxiosXHR<ProductDetail[]> = await this.productClient.get(
			"/v1/product/details",
			{
				params: {withExternalStocks: withExternalStocks, partNumber: partNumbers},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		);
		return response.data;
	}

	async getByPartNumber(partNumber): Promise<ProductModel> {
		const response: $AxiosXHR<ProductModel[]> = await this.productClient.get(
			`/v1/product/${partNumber}`,
		);
		return response.data;
	}


	setLanguage(language: string) {
		this.productClient.defaults.headers.common["Accept-Language"] = language;
	}
}

const ProductApiInstance = new ProductApi();
export default ProductApiInstance;
