// @flow
import type {CustomField, TicketStatusType} from "./articlesReducer";
import {ConversationType} from "./articlesReducer";
import {
	CREATE_ZENDESK_TICKET,
	CREATE_ZENDESK_TICKET_MESSAGE, FINISH_TICKET_MODAL,
	GET_ZENDESK_TICKET,
	GET_ZENDESK_TICKETS,
	RESET_ZENDESK_TICKET,
	SET_TICKET_ADDENDUM,
	SET_TICKET_FILTER,
	TICKET_OVERDUE_MODAL
} from "../actions/tickets";
import type {ZendeskUser} from "../actions/conversations";
import {GET_ZENDESK_USERS} from "../actions/conversations";
import {TicketStatusFilterOptions} from "../components/Tickets/TicketFilter";

export type Conversation = {
	id: number;
	type: ConversationType,
	title: String;
	content: String;
	createDate: Date;
	editDate: Date;
}

export type TicketConversation = Conversation & {
	type: 'TICKET',
	status: TicketStatusType;
	description: string;
	customFields: CustomField[];
	submitterId: number;
	assigneeId: number;
};

export type Attachment = {
	id: number,
	fileName: string,
	mappedContentUrl: string,
	contentType: string,
};

export type Message = {
	id: number,
	body: string,
	htmlBody: string,
	authorId: number,
	createdAt: string,
	attachments: Attachment[]
};

export const TicketFilterCriteriaType = Object.freeze({
	TEXT_SEARCH: "description",
	TICKET_STATUS: "status",
});

export type TicketFilterCriteria = {
	field: $Values<typeof TicketFilterCriteriaType>,
	value: string
}

export type ConversationsState = {
	tickets: TicketConversation[],
	current: {
		content: TicketConversation,
		messages: Message[]
	},
	isSubmitting: boolean,
	isFetching: boolean,
	notFound: boolean,
	zendeskUsers: ZendeskUser[],
	showCreateTicketAddendum: boolean,
	overdueTicketsAmount: number,
	showTicketOverdueModal: boolean,
	showFinishTicketModal: boolean,
	ticketToBeFinished: string,
	ticketFilter: Array<TicketFilterCriteria>
}

const defaultTicketFilter = [
	{
		field: TicketFilterCriteriaType.TICKET_STATUS,
		value: TicketStatusFilterOptions.OPEN
	},
];

const initialState: ConversationsState = {
	tickets: [],
	current: {},
	isSubmitting: false,
	isFetching: false,
	notFound: false,
	zendeskUsers: [],
	showCreateTicketAddendum: false,
	overdueTicketsAmount: 0,
	showTicketOverdueModal: false,
	showFinishTicketModal: false,
	ticketToBeFinished: null,
	ticketFilter: defaultTicketFilter
};

export const conversationsReducer = (state: ConversationsState = initialState, action): ConversationsState => {
	switch (action.type) {
		case SET_TICKET_ADDENDUM:
			return {
				...state,
				showCreateTicketAddendum: action.payload
			};
		case CREATE_ZENDESK_TICKET.SUCCESS:
			return {
				...state,
				isSubmitting: false,
				showCreateTicketAddendum: false,
			};
		case GET_ZENDESK_USERS.SUCCESS:
			return {
				...state,
				zendeskUsers: action.payload
			};
		case GET_ZENDESK_USERS.FAILURE:
			return {
				...state,
				zendeskUsers: []
			};

		case GET_ZENDESK_TICKETS.SUCCESS:
			return {
				...state,
				tickets: action.payload
			};
		case GET_ZENDESK_TICKET.REQUEST:
			return {
				...state,
				isFetching: true,
				notFound: false,
			};

		case GET_ZENDESK_TICKET.SUCCESS:
			return {
				...state,
				current: action.payload,
				isFetching: false,
				notFound: false,
			};

		case RESET_ZENDESK_TICKET:
			return {
				...state,
				current: {},
				isFetching: false,
				notFound: false,
			};
		case GET_ZENDESK_TICKET.FAILURE:
			return {
				...state,
				current: {},
				isFetching: false,
				notFound: true,
			};
		case CREATE_ZENDESK_TICKET_MESSAGE.REQUEST:
		case CREATE_ZENDESK_TICKET.REQUEST:
			return {
				...state,
				isSubmitting: true
			};

		case CREATE_ZENDESK_TICKET_MESSAGE.SUCCESS:
		case CREATE_ZENDESK_TICKET_MESSAGE.FAILURE:
		case CREATE_ZENDESK_TICKET.FAILURE:
			return {
				...state,
				isSubmitting: false
			};

		case TICKET_OVERDUE_MODAL.SHOW:
			return {
				...state,
				overdueTicketsAmount: action.payload,
				showTicketOverdueModal: true
			};

		case TICKET_OVERDUE_MODAL.HIDE:
			return {
				...state,
				showTicketOverdueModal: false
			};

		case FINISH_TICKET_MODAL.SHOW:
			return {
				...state,
				showFinishTicketModal: true,
				ticketToBeFinished: action.ticketId
			};

		case FINISH_TICKET_MODAL.HIDE:
			return {
				...state,
				showFinishTicketModal: false,
				ticketToBeFinished: null
			};

		case SET_TICKET_FILTER:
			const ticketFilter = state.ticketFilter.filter(criteria => criteria.field !== action.criteria.field);
			return {
				...state,
				ticketFilter: [...ticketFilter, action.criteria]
			};
		default:
			return state;
	}
};
